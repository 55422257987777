.contacts {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;

  &__link {
    color: #00A0F0;
    padding: 30px;
  }
}
