.app {
  height: 100vh;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #fff;

  &__content {
    display: flex;
    flex-direction: column;
  }
}
